import { useEffect } from 'react';
import { useLogout } from 'utils/token';

export default function Logout() {
  const logout = useLogout();

  useEffect(() => {
    logout();
  }, []);

  return null;
}
